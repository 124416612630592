<template>
  <div class="champion-person" id="champion" v-if="goldNum && lists">
    <div class="champion-title">{{ lists[0] }}</div>
    <div class="champion-indicator">
      {{ nowNum }}/<span class="lower">{{ listNum }}</span>
    </div>
    <div class="seize-a-seat"></div>
    <div class="champion-body">
      <van-swipe
        :autoplay="autoplay"
        class="my-swipe"
        :width="swiperWidth"
        :style="{ width: swiperWidth + 'px' }"
        indicator-color="white"
        ref="champion-swipe"
        :loop="loop"
        @change="swipeChange"
        :show-indicators="false"
      >
        <!-- 外层循环为list的数量/2向上取整（轮播个数） 内层为切割list每个轮播显示两条 -->
        <van-swipe-item v-for="num of listNum" :key="num" class="swipe-item" ref="swiperItem">
          <div class="carrier" v-for="(item, idx) in list.slice((num - 1) * 2, (num - 1) * 2 + 2)" :key="item.id" @click="$router.push(`/note/${item.id}?from_id=oly`)">
            <div class="swipe-left">
              <img :src="item.firstPicture" alt="" class="article-img" />
              <div class="text-box">
                <div class="title">
                  <div class="medal-img">
                    <img src="https://cdn.9kd.com/kdnet/olympic-medal-img%402x.png" alt="" />
                  </div>
                  <span
                    >第&nbsp;<span class="num">{{ idx == 0 ? goldNum - 2 * num + 2 : goldNum - 2 * num + 1 }}</span
                    >&nbsp;金</span
                  >
                </div>
                <div class="abstract van-multi-ellipsis--l3">{{ item.title }}</div>
              </div>
            </div>
            <div class="swipe-cheer" @click.stop="addCheer(item, idx == 0 ? 2 * num - 2 : 2 * num - 1)">
              <div class="olympic-cheer-img" ref="cheerImg"></div>
              <p class="cheer-num">{{ item.cheer > 999999 ? (item.cheer / 10000).toFixed(2) + 'w' : item.cheer }}</p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <olympic-nav v-if="goldNum > 10" :navName="navName" :index="2"></olympic-nav>
  </div>
</template>

<script>
export default {
  name: 'Champion-Person',
  components: {
    'olympic-nav': () => import(/* webpackChunkName: "hotpoint" */ '../../components/OlympicNav'),
  },
  props: {
    lists: {
      type: Array,
      default: function () {
        return [{}]
      },
    },
    goldNum: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      list: 0,
      nowNum: 1, //当前轮播
      navName: '冠军时光机',
      lastShow: false, // 最后一屏按钮显示
      listNum: Math.ceil((this.lists.length - 1) / 2), //轮播图item数
      id: this.$route.params.id || 0,
      channelId: this.$route.params.channelId || 0,
      swiperWidth: this.$Is_PC ? 480 : document.documentElement.offsetWidth - 20,
      gifFlag: false, //控制动图展示
      autoplay: this.$Is_PC ? 3000 : '',
      loop: this.$Is_PC ? true : false,
    }
  },
  created() {
    this.list = [...this.lists]
    this.list.splice(0, 1)
    // 最多显示五轮播
    if (this.listNum > 5) this.listNum = 5
    // 只有一条显示最后一屏样式
    if (this.list.length === 1) {
      this.lastShow = true
    } else {
      this.lastShow = false
    }
    // 返回金数容错，gold=>第几金字段
    // if (this.goldNum < this.lists.length - 1) {
    //   this.goldNum = this.lists.length - 1
    // }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.swiperItem) {
        this.swiperWidth = this.$Is_PC ? 480 : this.$refs.swiperItem[0].$el.offsetWidth
      }
    })
  },
  methods: {
    swipeChange(idx) {
      this.nowNum = idx + 1
      if (idx + 1 == this.listNum) {
        this.lastShow = true
      } else {
        this.lastShow = false
      }
    },
    async addCheer(item, idx) {
      item.cheer++
      if (!this.gifFlag) {
        this.gifFlag = true
        this.$refs.cheerImg[idx].style.background = "url('https://cdn.9kd.com/kdnet/olympic-cheer.gif') no-repeat"
        this.$refs.cheerImg[idx].style.backgroundSize = '100%'
        setTimeout(() => {
          this.$refs.cheerImg[idx].style.background = "url('https://cdn.9kd.com/kdnet/olympic-saoguagn.gif') no-repeat"
          this.$refs.cheerImg[idx].style.backgroundSize = '100%'
          this.gifFlag = false
        }, 1500)
      }
      let params = {
        articleId: item.id,
        num: 1,
        topicId: this.id,
      }
      let { data: res } = await this.$api.addCheer(params)
      if (res && res.data > item.cheer) {
        item.cheer = res.data
      }
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped lang="less">
.champion-person {
  position: relative;
  height: 379px;
  .seize-a-seat {
    width: 100%;
    height: 36px;
  }
  .champion-indicator {
    z-index: 1;
    position: absolute;
    right: 12px;
    top: 15px;
    width: 50px;
    height: 23px;
    opacity: 1;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    .lower {
      opacity: 0.8;
    }
  }
  .champion-title {
    z-index: 1;
    // margin-left: -10px;
    position: absolute;
    top: 0;
    left: -10px;
    box-sizing: border-box;
    padding: 9px 23px 9px 12px;
    height: 36px;
    background: #f7b92b;
    border-radius: 0 54px 54px 0;
    font-size: 20px;
    line-height: 18px;
    vertical-align: middle;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 5px rgba(233, 119, 35, 0.8);
  }
  .champion-body {
    .my-swipe {
      width: 100%;
      height: 100%;
      border-radius: 10px 10px 0 0;
      .van-swipe-item {
        width: 100%;
        height: 100%;
        background-color: #5397eb;
      }
    }
    .carrier {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 131px;
      margin-top: 12px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 18px 0px rgba(55, 170, 251, 0.27) inset;
      .swipe-left {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 100%;
        .article-img {
          width: 129px;
          height: 100%;
          flex-shrink: 0;
          margin-right: 16px;
          border-radius: 10px 0 0 10px;
          background-color: #5397eb;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          //   width: 125px;
          .title {
            margin-bottom: 12px;
            font-size: 18px;
            font-weight: 500;
            color: #3c5bae;
            display: flex;
            align-items: center;
            .medal-img {
              width: 21px;
              height: 25px;
              margin-right: 7px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .num {
              color: #f7321c;
            }
          }
          .abstract {
            height: 56px;
            font-size: 14px;
            text-align: left;
            color: #999;
            line-height: 20px;
            word-break: break-all;
          }
        }
      }
      .swipe-cheer {
        width: 73px;
        height: 100%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .olympic-cheer-img {
          width: 54px;
          height: 52px;
          background: url('https://cdn.9kd.com/kdnet/olympic-saoguagn.gif') no-repeat;
          background-size: 100%;
        }
        .cheer-num {
          color: #f7321c;
          font-size: 16px;
        }
      }
    }
  }
}
.h240 {
  height: 240px;
}
</style>
